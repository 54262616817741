import { filter, flatMap, isEmpty, map, set } from 'lodash'
import { PATHS } from '~/common/constants'
import { doubleQuote, email, minValue, required } from '~/common/validator'
import { AutoCompleteField, NumberField, PhoneField, TextField } from '~/components/fields'

import { action, computed, observable, store } from '~/common/mobx.decorator'
import { appStore, authStore, masterStore, notifyStore, routerStore } from '~/stores'
import {
	apolloClient,
	CREATE_WORKER_MUTATION,
	SEND_OTP_CODE_V2_MUTATION,
	SIGN_UP_WORKER_MUTATION,
	UPDATE_WORKER_LOCATION_MUTATION,
	VERIFY_OTP_CODE_MUTATION,
} from '~/common/apollo'
import { captureException, handleLoadTrackingPixel, isExistingEmailError, isPhoneInvalidError, RECAPTCHA_CLIENT_KEY, toFormErrors } from '~/common/helpers'
import { EmailField } from '@opus/web.core.form.email-field'
import { otpDialogStore } from '~/components/otp-dialog/otp-dialog.store'

@store()
class CareSignupStore {
	@observable showSuccess = false
	@observable workerInfo = null
	@observable businessDay = 0
	@observable programmaticUrl = null
	@observable conversionUrl = null
	@observable exclusionUrl = null
	@observable showDialogOtp = false
	@observable confirm = false
	@observable phoneInvalidConfirm = false
	@observable phoneInvalidMessage = ''
	@observable sendOtpCodeDone = false

	@computed
	get programmaticTracking() {
		return this.programmaticUrl
	}

	@computed
	get conversionTracking() {
		return this.conversionUrl
	}

	@computed
	get exclusionTracking() {
		return this.exclusionUrl
	}

	@computed
	get embedded() {
		return routerStore.queryParams?.embedded
	}

	@computed
	get disciplineOptions() {
		return masterStore.disciplines
	}

	@computed
	get referralValue() {
		const REFERRAL_VAL = 'Referral'
		return REFERRAL_VAL
	}

	@computed
	get specialtyOptions() {
		return flatMap(
			masterStore.disciplines?.map(({ dependent_values = [], value }) =>
				dependent_values.map((dep) => ({
					...dep,
					parentValue: value,
				}))
			)
		)
	}

	@computed
	get locationOptions() {
		return masterStore.locations
	}

	@computed
	get referralOptions() {
		return masterStore.referrals
	}

	@computed
	get referralRelationshipOptions() {
		return masterStore.referralRelationships
	}

	@computed
	get fields() {
		return [
			{
				name: 'discipline',
				label: 'DISCIPLINE',
				Field: AutoCompleteField,
				validate: [required],
				options: this.disciplineOptions,
			},
			{
				name: 'specialty',
				label: 'SPECIALTY',
				parentName: 'discipline',
				Field: AutoCompleteField,
				options: this.specialtyOptions,
			},
			{
				name: 'experience',
				label: 'YEARS_OF_EXPERIENCE',
				placeholder: '$PLACEHOLDERS.YEARS_OF_EXPERIENCE',
				Field: NumberField,
				validate: [required, minValue(0)],
			},
		]
	}

	@computed
	get stateFields() {
		return [
			{
				name: 'location1',
				label: 'DESTINATION_#1',
				Field: AutoCompleteField,
				options: this.locationOptions,
			},
			{
				name: 'location2',
				label: 'DESTINATION_#2',
				Field: AutoCompleteField,
				options: this.locationOptions,
			},
			{
				name: 'location3',
				label: 'DESTINATION_#3',
				Field: AutoCompleteField,
				options: this.locationOptions,
			},
		]
	}

	@computed
	get contactFields() {
		return [
			{
				name: 'firstName',
				label: 'FIRST_NAME',
				placeholder: '$PLACEHOLDERS.FIRST_NAME',
				Field: TextField,
				validate: [required, doubleQuote],
			},
			{
				name: 'lastName',
				label: 'LAST_NAME',
				placeholder: '$PLACEHOLDERS.LAST_NAME',
				Field: TextField,
				validate: [required, doubleQuote],
			},
			{ name: 'phone', Field: PhoneField },
			{
				name: 'email',
				Field: EmailField,
				label: 'EMAIL',
				placeholder: '$PLACEHOLDERS.EMAIL',
				validate: [required, email],
			},
		]
	}

	@computed
	get referredByFields() {
		return [
			{
				name: 'referredByName',
				label: 'REFERRED_BY_NAME',
				placeholder: '$PLACEHOLDERS.REFERRED_BY_NAME',
				Field: TextField,
				validate: [required],
			},
			{
				name: 'referralRelationship',
				label: 'REFERRAL_RELATIONSHIP',
				Field: AutoCompleteField,
				options: this.referralRelationshipOptions,
				validate: [required],
			},
		]
	}

	@computed
	get referralFields() {
		return [
			{
				name: 'referralMethod',
				label: 'HEAR_US_QUESTION',
				Field: AutoCompleteField,
				options: this.referralOptions,
			},
		]
	}

	@action
	validateStates = async (states = []) => {
		return states?.length >= 3 ? undefined : '$ERRORS.INVALID_STATES'
	}

	@action
	setShowDialogOtp = (value) => {
		this.showDialogOtp = value
	}

	@action
	handleApply = async (variables, { setErrors }) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'createIntegrationWorker',
					})
					let modVariables = {
						recaptchaToken: token,
						...variables,
					}
					if (!modVariables.referralMethod || modVariables.referralMethod !== this.referralValue) {
						delete modVariables.referralRelationship
						delete modVariables.referredByName
					}

					const signedBlobIds = filter(map(modVariables.resumes, (resume) => !resume._destroy && resume.signedBlobId))

					const { data } = await apolloClient.mutate({
						mutation: CREATE_WORKER_MUTATION,
						variables: {
							...modVariables,
							companyId: appStore.id,
							signedBlobId: signedBlobIds?.[0],
						},
					})
					this.workerInfo = data.createIntegrationWorker
					this.businessDay = data.createIntegrationWorker?.worker?.company?.salesforceIntegration?.businessDay

					const timestamp = +Math.floor(Date.now() / 1000)
					const trackingid = process.env.REACT_APP_PROGRAMMATIC_ID
					this.programmaticUrl = PATHS.outside_care.programmaticUrl.replace('[timestamp]', timestamp).replace('[trackingid]', trackingid)

					handleLoadTrackingPixel()

					const conversionid = process.env.REACT_APP_CONVERSION_ID
					this.conversionUrl = PATHS.outside_care.conversionPixelUrl.replace('[conversionid]', conversionid)

					const exclusionid = process.env.REACT_APP_EXCLUSION_ID
					this.exclusionUrl = PATHS.outside_care.exclusionPixelUrl.replace('[exclusionid]', exclusionid)

					this.showSuccess = true
					if (window?.fbq && typeof window?.fbq === 'function') {
						window.fbq('track', 'CompleteRegistration')
					}
					resolve()
				} catch (error) {
					captureException('Care Signup', error)
					const errors = toFormErrors(error) || {}
					const resumeError = errors?.resume
					set(errors, 'resumes', resumeError)
					setErrors(errors)
					reject(errors)
				}
			})
		})
	}

	@action
	handleSubmitRef = async (variables) => {
		await apolloClient.mutate({
			mutation: UPDATE_WORKER_LOCATION_MUTATION,
			variables: { ...variables, id: this.workerInfo?.worker?.id },
			context: {
				headers: {
					'Auth-Token': this.workerInfo?.authToken,
				},
			},
		})
	}

	@action
	handleClickNavigateJobs = () => {
		window.top.location.href = PATHS.outside_care.travel_jobs
	}
	@action
	signUpWorker = async (variables, { setErrors }, setShowPhoneVerifyModal) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'createIntegrationWorker',
					})
					let updatedVariables = {
						recaptchaToken: token,
						...variables,
					}
					const { data } = await apolloClient.mutate({
						mutation: SIGN_UP_WORKER_MUTATION,
						variables: { ...updatedVariables },
					})
					const authToken = data?.createIntegrationWorker?.authToken
					await authStore.changeToken(authToken)
					await notifyStore.success('$MESSAGES.SUCCESSFUL')
					await authStore.handleAuthAnonymous(false)

					await otpDialogStore.setPhoneNumber(variables.phone)
					await otpDialogStore.resetRemainingChangePhone()
					await otpDialogStore.setRemainingResendCode(null)
					await setShowPhoneVerifyModal(true)
					resolve()
				} catch (error) {
					if (isExistingEmailError(error)) {
						this.confirm = true
					}
					const errors = toFormErrors(error) || {}
					if (errors?.hasOwnProperty('__ERROR')) {
						notifyStore.error(error.message)
					} else {
						setErrors(errors)
					}
					reject(errors)
				}
			})
		})
	}

	@action
	toggleConfirm = async (confirm) => {
		this.confirm = confirm
	}

	@action
	handleSendOTP = async (variables) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					this.sendOtpCodeDone = false
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, { action: 'sendOtpCodeV2' })
					const variableFinal = { phoneNumber: variables, recaptchaToken: token }
					await apolloClient.mutate({
						mutation: SEND_OTP_CODE_V2_MUTATION,
						variables: variableFinal,
					})
					// const { data } = response
					// const remainingExpirationTime = data?.sendOtpCodeV2?.worker?.remainingExpirationTime
					await notifyStore.success('$MESSAGES.SEND_OTP')
					this.sendOtpCodeDone = true
					await resolve(60000)
				} catch (error) {
					if (isPhoneInvalidError(error)) {
						this.phoneInvalidConfirm = true
						this.sendOtpCodeDone = true
						this.phoneInvalidMessage = error?.message
						return
					} else {
						notifyStore.error(error?.message)
					}
				}
			})
		})
	}

	@action
	handleSubmitOTP = async (variables, setShowDialogOtp) => {
		try {
			await apolloClient.mutate({
				mutation: VERIFY_OTP_CODE_MUTATION,
				variables,
			})
			await setShowDialogOtp(false)
			await authStore.verifyToken()
			const quickApplyJobID = JSON.parse(window.localStorage.getItem('quickApplyJobID'))
			if (!isEmpty(quickApplyJobID)) {
				window.localStorage.removeItem('quickApplyJobID')
				await routerStore.goPage(`${PATHS.care.jobs}/${quickApplyJobID?.jobTitle}`)
			} else {
				await routerStore.goPage(PATHS.common.home)
			}
			await notifyStore.success('$MESSAGES.VERIFY_PHONE')
		} catch (error) {
			otpDialogStore.setSubmitError(true)
			notifyStore.error(error?.message)
		}
	}

	@action
	setPhoneInvalidConfirm = (value) => {
		this.phoneInvalidConfirm = value
	}
}

export const careSignupStore = new CareSignupStore()
