import { gql } from '@apollo/client'
import {
	DIRECT_UPLOAD_FRAGMENT,
	EMERGENCY_CONTACT_FRAGMENT,
	IMAGE_FILE_FRAGMENT,
	SALESFORCE_INTEGRATION_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	WORKED_TIMESHEET_FRAGMENT,
	WORKER_ADDRESS_FRAGMENT,
	WORKER_CERTIFICATION_FRAGMENT,
	WORKER_FRAGMENT,
} from '../apollo.fragment'

export const SIGNIN_WORKER_MUTATION = gql`
	mutation signInWorker($email: String!, $password: String!, $recaptchaToken: String, $socialAuthenticationId: String) {
		signInWorker(recaptchaToken: $recaptchaToken, email: $email, password: $password, socialAuthenticationId: $socialAuthenticationId) {
			authToken
			notificationChannel
			worker {
				...workerFragment
				recruiter {
					id
					name
					phone
					email
					title
				}
			}
		}
	}

	${WORKER_FRAGMENT}
`

export const VERIFY_OAUTH_LOGIN = gql`
	mutation verifyOauthLogin(
		$providerName: SocialProviderEnum!
		$clientId: String!
		$clientToken: String
		$email: String
		$code: String
		$redirectUri: String
		$recaptchaToken: String
	) {
		verifySocialAuthentication(
			providerName: $providerName
			clientId: $clientId
			clientToken: $clientToken
			email: $email
			code: $code
			redirectUri: $redirectUri
			recaptchaToken: $recaptchaToken
		) {
			authToken
			emailHasAccount
			notificationChannel
			userSocialAuthentication {
				email
				providerName
				id
				providerUuid
				userId
			}
			worker {
				company {
					id
					logoUrl
					name
					country
					city
					address
				}
				email
				firstName
				id
				lastName
				phone
				phoneVerifiedAt
				workAuthorized
				changePasswordRequired
			}
		}
	}
`

export const CREATE_WORKER_MUTATION = gql`
	mutation createWorker(
		$recaptchaToken: String
		$firstName: String!
		$lastName: String!
		$email: String!
		$companyId: String!
		$phone: String!
		$discipline: String!
		$specialty: String
		$experience: String!
		$referralMethod: String
		$referredByName: String
		$referralRelationship: String
		$signedBlobId: String
		$socialAuthenticationId: String
	) {
		createIntegrationWorker(
			socialAuthenticationId: $socialAuthenticationId
			recaptchaToken: $recaptchaToken
			firstName: $firstName
			lastName: $lastName
			email: $email
			companyId: $companyId
			phone: $phone
			signedBlobId: $signedBlobId
			referralMethod: $referralMethod
			referredByName: $referredByName
			referralRelationship: $referralRelationship
			# signedBlobIds: $signedBlobIds
			workExperienceOverviewAttributes: { discipline: $discipline, primarySpecialty: $specialty, yearsOfExperience: $experience }
			source: opus_web_portal
		) {
			authToken
			worker {
				...workerFragment
				company {
					salesforceIntegration {
						...salesforceIntegrationFragment
					}
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${SALESFORCE_INTEGRATION_FRAGMENT}
`

export const UPDATE_WORKER_MUTATION = gql`
	mutation updateWorker($id: String!, $referral: String!) {
		updateIntegrationWorker(id: $id, referralMethod: $referral) {
			id
		}
	}
`

export const UPDATE_WORKER_LOCATION_MUTATION = gql`
	mutation createWorkingPreferredLocations($id: String!, $location1: String, $location2: String, $location3: String) {
		createWorkingPreferredLocations(
			id: $id
			workingPreferredLocationsAttributes: [{ preferredWorkingState: $location1 }, { preferredWorkingState: $location2 }, { preferredWorkingState: $location3 }]
		) {
			success
		}
	}
`

export const SEND_PASSWORD_RESET_WORKER_MUTATION = gql`
	mutation sendPasswordResetWorker($email: String!) {
		sendPasswordResetWorker(email: $email) {
			success
		}
	}
`

export const RESET_PASSWORD_WORKER_MUTATION = gql`
	mutation resetPasswordWorker($token: String!, $password: String!, $passwordConfirmation: String!, $recaptchaToken: String) {
		resetPasswordForUser(recaptchaToken: $recaptchaToken, token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
			success
		}
	}
`

export const UPDATE_WORK_EXPERIENCE_MUTATION = gql`
	mutation updateWorkExperience($id: String!, $experiences: [WorkExperienceArgument!]) {
		updateIntegrationWorker(id: $id, workExperiencesAttributes: $experiences) {
			workExperiences {
				...workExperienceFragment
			}
		}
	}
	${WORK_EXPERIENCE_FRAGMENT}
`

export const UPDATE_WORKER_LICENSE_MUTATION = gql`
	mutation updateWorkerLicenses($id: String!, $licenses: [WorkerCertificationArgument!]) {
		updateIntegrationWorker(id: $id, workerCertificationsAttributes: $licenses) {
			id
			workerCertifications {
				...workerCertificationFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const CREATE_DIRECT_UPLOAD_MUTATION = gql`
	mutation createDirectUpload($filename: String!, $byteSize: Int!, $checksum: String!, $contentType: String!) {
		createDirectUpload(filename: $filename, byteSize: $byteSize, checksum: $checksum, contentType: $contentType) {
			...directUploadFragment
		}
	}
	${DIRECT_UPLOAD_FRAGMENT}
`

export const CREATE_WORKED_TIMESHEET_MUTATION = gql`
	mutation createWorkedTimesheet($timesheetImagesSignedBlobIds: [String!]!) {
		createWorkedTimesheet(timesheetImagesSignedBlobIds: $timesheetImagesSignedBlobIds, source: opus_web_portal) {
			...workedTimesheetFragment
			timesheetImages {
				...imageFileFragment
			}
		}
	}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const DESTROY_FILE_MUTATION = gql`
	mutation destroyFile($id: String!) {
		destroyFile(id: $id) {
			id
			success
		}
	}
`

export const UPDATE_WORKED_TIMESHEET_MUTATION = gql`
	mutation updateWorkedTimesheet($id: String!, $signedBlobId: [String!]!) {
		updateWorkedTimesheet(id: $id, signedBlobId: $signedBlobId) {
			...workedTimesheetFragment
			timesheetImages {
				...imageFileFragment
			}
		}
	}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`
export const UPDATE_WORKER_RESUME_MUTATION = gql`
	mutation($resumes_signed_blob_ids: [String!]) {
		createOrUpdateWorkerResumes(resumesSignedBlobIds: $resumes_signed_blob_ids) {
			id
			email
			resumes {
				id
				filename
				fileUrl
				contentType
				blobId
				imageUrls(size: ["100x100", "200x200", "500x500"])
			}
		}
	}
`

export const SEND_OTP_CODE_MUTATION = gql`
	mutation sendOtpCodeMutation($phoneNumber: String!) {
		sendOtpCode(phoneNumber: $phoneNumber) {
			success
			worker {
				remainingExpirationTime
			}
		}
	}
`

export const SEND_OTP_CODE_V2_MUTATION = gql`
	mutation sendOtpCodeV2Mutation($phoneNumber: String!, $recaptchaToken: String) {
		sendOtpCodeV2(phoneNumber: $phoneNumber, recaptchaToken: $recaptchaToken) {
			success
			worker {
				remainingExpirationTime
			}
		}
	}
`

export const VERIFY_OTP_CODE_MUTATION = gql`
	mutation verifyOtpCodeMutation($otpCode: String!, $phoneNumber: String!) {
		verifyOtpCode(otpCode: $otpCode, phoneNumber: $phoneNumber) {
			success
		}
	}
`

export const UPDATE_WORKER_INFO_MUTATION = gql`
	mutation(
		$firstName: String
		$lastName: String
		$email: String
		$phone: String
		$availableStartDate: ISO8601Date
		$timeOffRequested: String
		$shiftRequested: String
		$workerAddress: WorkerAddressArgument
		$emergencyContact: EmergencyContactArgument
		$avatarSignedBlobId: String
	) {
		updateWorker(
			firstName: $firstName
			lastName: $lastName
			email: $email
			phone: $phone
			availableStartDate: $availableStartDate
			timeOffRequested: $timeOffRequested
			shiftRequested: $shiftRequested
			workerAddress: $workerAddress
			emergencyContact: $emergencyContact
			avatarSignedBlobId: $avatarSignedBlobId
		) {
			...workerFragment
			emergencyContact {
				...emergencyContactFragment
			}
			workerAddress {
				...workerAddressFragment
			}
		}
	}
	${WORKER_FRAGMENT}
	${EMERGENCY_CONTACT_FRAGMENT}
	${WORKER_ADDRESS_FRAGMENT}
`

export const CREATE_WORKER_CERTICATION_MUTATION = gql`
	mutation createWorkerCertification(
		$licenseDiscipline: String!
		$licenseState: String!
		$licenseNumber: String!
		$compactLicense: Boolean!
		$expirationDate: String!
		$certificationType: String!
		$licenseImages: [FileArgument!]!
	) {
		createWorkerCertification(
			licenseDiscipline: $licenseDiscipline
			licenseState: $licenseState
			licenseNumber: $licenseNumber
			compactLicense: $compactLicense
			expirationDate: $expirationDate
			certificationType: $certificationType
			licenseImages: $licenseImages
		) {
			...workerCertificationFragment
			licenseImages {
				...imageFileFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`
export const UPDATE_SHOW_PARTIAL_JOBS = gql`
	mutation updateIntegrationWorker($updateIntegrationWorkerId: String!, $settings: JSON) {
		updateIntegrationWorker(id: $updateIntegrationWorkerId, settings: $settings) {
			id
			settings
		}
	}
`

export const DISCONNECT_SOCIAL_AUTHENTICATION = gql`
	mutation DisconnectSocialAuthentication($socialAuthenticationId: String!) {
		disconnectSocialAuthentication(socialAuthenticationId: $socialAuthenticationId) {
			success
		}
	}
`
export const VERIFY_EXISTED_EMAIL = gql`
	mutation VerifyExistedEmail($email: String!, $recaptchaToken: String) {
		verifyExistedEmail(email: $email, recaptchaToken: $recaptchaToken) {
			existed
		}
	}
`
export const SIGN_UP_WORKER_MUTATION = gql`
	mutation CreateIntegrationWorker(
		$firstName: String!
		$lastName: String!
		$email: String!
		$phone: String!
		$password: String!
		$passwordConfirmation: String!
		$recaptchaToken: String
		$referrerCode: String
		$noSms: Boolean
	) {
		createIntegrationWorker(
			firstName: $firstName
			lastName: $lastName
			email: $email
			phone: $phone
			password: $password
			passwordConfirmation: $passwordConfirmation
			recaptchaToken: $recaptchaToken
			referrerCode: $referrerCode
			noSms: $noSms
		) {
			authToken
			notificationChannel
			worker {
				phoneVerifiedAt
				firstName
				lastName
				id
				email
				phone
			}
		}
	}
`

export const SUPPORT_DOCUMENTS_MUTATION = gql`
	query {
		supportDocuments {
			id
			documentType
			document {
				id
				fileUrl
				filename
				contentType
				blobId
				thumbnails
				imageUrls(size: ["100x100", "200x200", "500x500"])
			}
		}
	}
`

export const CREATE_OR_UPDATE_SUPPORT_DOCUMENTS_MUTATION = gql`
	mutation($supportDocumentsAttributes: [SupportDocumentArgument!]!) {
		createOrUpdateSupportDocument(supportDocumentsAttributes: $supportDocumentsAttributes) {
			id
			documentType
			document {
				id
				fileUrl
				filename
				contentType
				contentType
				blobId
				thumbnails
				imageUrls(size: ["100x100", "200x200", "500x500"])
			}
		}
	}
`

export const DELETE_WORKER_SPECIALTY = gql`
	mutation($id: String!) {
		destroyWorkerSpecialty(id: $id) {
			success
			id
		}
	}
`

export const CREATE_OR_UPDATE_WORKER_SPECIALTY = gql`
	mutation($workerSpecialtiesAttributes: [WorkerSpecialtyArgument!]!) {
		createOrUpdateWorkerSpecialty(workerSpecialtiesAttributes: $workerSpecialtiesAttributes) {
			id
			specialty
			completedChecklist
			category
			isPrimary
			specialtyLongName
			status
			checklistFile {
				blobId
				contentType
				filename
				fileUrl
				id
			}
			specialtyType
			workerSkillChecklistSections {
				id
				workerSpecialty {
					status
				}
				name
				workerSkillChecklists {
					id
					question
					proficiency
					frequency
				}
			}
		}
	}
`

export const GET_WORKER_SPECIALTIES_QUERY = gql`
	query {
		workerSpecialties {
			id
			specialty
			completedChecklist
			isPrimary
			category
			specialtyLongName
			status
			checklistFile {
				blobId
				contentType
				filename
				fileUrl
				id
			}
			specialtyType
			workerSkillChecklistSections {
				id
				name
				workerSpecialty {
					status
				}
				workerSkillChecklists {
					id
					question
					proficiency
					frequency
				}
			}
		}
	}
`

export const UPDATE_WORKER_PHONE = gql`
	mutation UpdateWorkerPhone($phone: String) {
		updateWorker(phone: $phone) {
			phone
			phoneVerifiedAt
		}
	}
`

export const QUICK_APPLY_JOB_MUTATION = gql`
	mutation quickApplyJob(
		$firstName: String!
		$lastName: String!
		$email: String!
		$phone: String!
		$recaptchaToken: String
		$workerSpecialtiesAttributes: [WorkerSpecialtyArgument!]
		$workingPreferredLocationsAttributes: [WorkingPreferredLocationArgument!]
		$externalJobId: String!
		$referrerCode: String
	) {
		quickApplyJob(
			firstName: $firstName
			lastName: $lastName
			email: $email
			phone: $phone
			recaptchaToken: $recaptchaToken
			workerSpecialtiesAttributes: $workerSpecialtiesAttributes
			workingPreferredLocationsAttributes: $workingPreferredLocationsAttributes
			externalJobId: $externalJobId
			referrerCode: $referrerCode
		) {
			success
		}
	}
`

export const UPDATE_WORKER_NO_SMS = gql`
	mutation updateWorkerNoSMS($noSms: Boolean) {
		updateWorker(noSms: $noSms) {
			noSms
		}
	}
`

export const UPDATE_WORKER_NO_EMAIL = gql`
	mutation updateWorkerNoEmail($noEmail: Boolean) {
		updateWorker(noEmail: $noEmail) {
			noEmail
		}
	}
`
