import { gql } from '@apollo/client'
import {
	EMERGENCY_CONTACT_FRAGMENT,
	IMAGE_FILE_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	WORK_EXPERIENCE_OVERVIEW_FRAMENT,
	WORKED_TIMESHEET_FRAGMENT,
	WORKER_ADDRESS_FRAGMENT,
	WORKER_CERTIFICATION_FRAGMENT,
	WORKER_CREDENTIAL_FRAGMENT,
	WORKER_FRAGMENT,
	WORKING_AUTHORIZATION_FRAGMENT,
	WORKER_REFERENCE_FRAGMENT,
} from '../apollo.fragment'

export const VERIFY_WORKER_QUERY = gql`
	query verifyWorker($token: String!) {
		workerToken(token: $token) {
			token
			notificationChannel
			worker {
				...workerFragment
				recruiter {
					id
					name
					phone
					email
					title
				}
				company {
					address
					id
					country
					phone
				}
			}
		}
	}

	${WORKER_FRAGMENT}
`

export const GET_WORKER_DETAIL_QUERY = gql`
	query getWorkerDetailQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			workExperienceOverview {
				...workExperienceOverview
			}
			workingAuthorization {
				...workingAuthorizationFragment
			}
			workExperiences {
				...workExperienceFragment
			}
			workerCertifications {
				...workerCertificationFragment
			}
			emergencyContact {
				...emergencyContactFragment
			}
			workerAddress {
				...workerAddressFragment
			}
			supportDocuments {
				id
				documentType
				document {
					id
					contentType
					fileUrl
					filename
					thumbnails
					blobId
					imageUrls(size: ["100x100", "200x200", "500x500"])
				}
			}
			workerReferences {
				...workerReferenceFragment
			}
			workingPreferredLocations {
				id
				preferredWorkingCity
				preferredWorkingState
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORKING_AUTHORIZATION_FRAGMENT}
	${WORK_EXPERIENCE_FRAGMENT}
	${WORKER_CERTIFICATION_FRAGMENT}
	${EMERGENCY_CONTACT_FRAGMENT}
	${WORK_EXPERIENCE_OVERVIEW_FRAMENT}
	${WORKER_ADDRESS_FRAGMENT}
	${WORKER_REFERENCE_FRAGMENT}
`

export const GET_WORKER_DETAIL_FOR_TIMECARDS_QUERY = gql`
	query getWorkerDetailTimeQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			workedTimesheets {
				...workedTimesheetFragment
				timesheetImages {
					imageUrls(size: ["100x100", "200x200"])
					...imageFileFragment
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_CREDENTIALING_URL_QUERY = gql`
	query getWorkerDetailCredentialingQuery($id: String!) {
		worker(id: $id) {
			id
			workAuthorized
			phone
			phoneVerifiedAt
		}
	}
`

export const GET_WORKER_CERTIFICATIONS_QUERY = gql`
	query getWorkerCertificationsQuery($id: String!) {
		workerCertifications(workerId: $id) {
			...workerCertificationFragment
			licenseImages {
				...imageFileFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_WORKER_CREDENTIALS_QUERY = gql`
	query getWorkerCredentialsQuery($filter: WorkerCredentialStatusEnum, $after: String, $before: String, $first: Int, $last: Int) {
		workerCredentials(filter: $filter, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerCredentialFragment
			}
		}
	}

	${WORKER_CREDENTIAL_FRAGMENT}
`
export const GET_WORKER_SOCIAL_QUERY = gql`
	query getWorkerSocialQuery {
		workerSocialAuthentications {
			providerName
			id
			providerUuid
			email
		}
	}
`
export const GET_WORKER_PARTIAL_SETTINGS_QUERY = gql`
	query getWorkerPartialSettings($workerId: String!) {
		appInit(workerId: $workerId) {
			worker {
				id
				email
			}
		}
	}
`

export const GET_SIGNED_URLS = gql`
	query getSignedUrls($blobIds: [String!]!) {
		signedUrls(blobIds: $blobIds) {
			id
			fileUrl
			blobId
			imageUrls(size: ["100x100", "200x200", "500x500"])
		}
	}
`

export const GET_RECRUITER_INFO = gql`
	query getRecruiterInfo($workerId: String!) {
		worker(id: $workerId) {
			recruiter {
				companyId
				email
				id
				name
				phone
				title
			}
		}
	}
`

export const GET_MASTER_DATA = gql`
	query getMasterData {
		appInit {
			masterData
		}
	}
`

export const GET_AUTHORIZED_WORKER = gql`
	query getAuthorizedWorker($workerId: String!) {
		worker(id: $workerId) {
			workAuthorized
			phone
			lastName
			firstName
			id
			phoneVerifiedAt
		}
	}
`
export const GET_WORKER_SPECIALTIES = gql`
	query getWorkerSpecialties {
		workerSpecialties {
			status
			id
			isExpired
			isPrimary
			completedChecklist
			category
			specialty
			specialtyId
			specialtyLongName
		}
	}
`
export const GET_WORKER_NO_SMS = gql`
	query getWorkerNoSMS($workerId: String!) {
		worker(id: $workerId) {
			noSms
		}
	}
`

export const GET_WORKER_NO_EMAIL = gql`
	query getWorkerNoEMAIL($workerId: String!) {
		worker(id: $workerId) {
			noEmail
		}
	}
`

export const CHECK_REMAINING_OTP_REQUESTS = gql`
	query checkRemainingOtpRequests($phone: String) {
		checkRemainingOtpRequests(phone: $phone) {
			remainingChangePhone
			remainingResendCode
		}
	}
`
